import View from "../View";

class ParkOptionsView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".parks-wrapper");
    // this.imageSlider(".selection__container", 2, 2);
  }

  addHandlerRenderPark(handler) {
    document.addEventListener("click", function (e) {
      if (e.target.closest(".park__choice")) {
        const parkID = e.target.closest(".park__choice").id;
        // console.log(parkID);
        handler(parkID);
      }
      return;
    });
  }

  createMarkUp() {
    return this._data
      .map(function (park) {
        return `
        <div class="selection__box swiper-slide park__choice" id=${park.id}>
            <img
            class="selection__box-img"
            src=${park.images[0]}
            alt="Selection Image"
            />
            <p class="selection__box-title">${park.name}</p>
        </div>  
    `;
      })
      .join(" ");
  }
}

export default new ParkOptionsView();
