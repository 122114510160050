import uniqid from "uniqid";

export default [
  {
    name: "Black Rose Bar",
    id: uniqid(),
    hours: "Tue-Sat: 6pm - 3am <br> Mon: Closed",
    description: `One of the classiest evening venues in Addis, Black Rose bar sits above the well-regarded Diplomat Restaurant, inviting diners to continue their night in the dimly lit space of the Black Rose. Here, locals mingle with visitors, and happy hour is a great time to go for drinks.`,
    location: "Africa Ave, Addis Ababa Ethiopia",
    isVegan: false,
    coordinates: [8.991645342346832, 38.784494460779285],

    img:
      "https://guidetoethiopia.com/wp-content/uploads/2017/09/black-rose.jpg",
  },
  {
    name: "YOD Abyssinia Traditional Restaurant",
    id: uniqid(),
    hours: "Tue-Sun:  12pm - 1am <br> Mon: 7pm - 12:30am",
    description: `
    YOD Abyssinia Traditional Restaurant, established in 2003, is one of the pioneering and most reputable traditional restaurants in Addis Ababa, introducing proudly the Ethiopian hospitality through diverse cuisines, cultural dance and music. ‘YOD’ means ‘witness or speak out’ in the Gurage ethnic group where the owner, Ato Tizazu Kore, is originally from. And ‘Abyssinia’ is the ancient name of Ethiopia and the region known today as Horn of Africa.
    `,
    location: "Bole Medhaniyalem, Addis Ababa",
    isVegan: true,
    coordinates: [8.991864177686844, 38.79356811533996],
    img:
      "https://ethiopianshebamiles.azurewebsites.net/images/default-source/benefit-icon/yod-abyssinia.jpg?sfvrsn=4f9f001d_1",
  },
  {
    name: "Haile's Mojito Bar",
    id: uniqid(),
    hours: "Tue-Sun:  12pm - 1am <br> Mon: 7pm - 12:30am",
    description: `
      A beautiful  and simple location to grab drinks and food if wanted. Haile's offers a variety of local and international drinks to it's customers. Decorated for comfort, Haile's is a great spot to grab drinks with friends. 
    `,
    location:
      "Behind Atlas International, Mickey Leland St, Addis Ababa Ethiopia",
    isVegan: true,
    coordinates: [9.00560226149541, 38.784152636898526],
    img:
      "https://lh5.googleusercontent.com/p/AF1QipP6n0qFhGjSgbwvCgy1h-lA4fA8qEHulje9Yqao=w400-h300-k-no",
  },
  {
    name: "Cascara Coffee & Cocktail's",
    id: uniqid(),
    hours: "Mon-Sun:  12pm - 11pm",
    description: `
      Adjacent to the main lobby (Regency Hotel) is a place where you can enjoy the stunning view of the inner courtyard with water features and colorful flower beds. We serve coffee-flavored cocktails and gourmet bites. Enjoy the music and the lively atmosphere.
    `,
    location: "Meskell Square, Addis Ababa 1250 Ethiopia",
    isVegan: true,
    coordinates: [9.00560226149541, 38.784152636898526],
    img:
      "https://www.hyattrestaurants.com/uploaded/restaurant_logos/restaurant_logo-1562767565.jpg",
  },
  {
    name: "Torpedo Tejbet",
    id: uniqid(),
    hours: "Mon-Sun: 8:30pm - 3am",
    description: `
      Very lively bar/night club located in Lalibela, Ethiopia. It's possibly the most popular place in the city and a very exciting place to go dance and  have  a relaxing time.
    `,
    location: "Across From Police Station, Lalibela, Ethiopia",
    isVegan: true,
    coordinates: [12.038301596475108, 39.046280773850064],
    img: "https://media-cdn.tripadvisor.com/media/photo-s/07/4c/14/93/torpedo-tejbet.jpg",
  },
];
