import View from "./View";

class ShoppingView extends View {
  constructor() {
    super();
    this.imageSlider(".display__box-img", 1, 1, 1);
  }
}

export default new ShoppingView();
