if (module.hot) {
  module.hot.accept();
}

//data
import restaurants from "./restaurants";
import bars from "./bars";
import parks from "./data/parks/parks";
import cities from "./data/cities/cities";
//restuarant & map view
import mapView from "./views/Restaurants/mapView";
import restaurantView from "./views/Restaurants/restaurantsView";
import barsView from "./views/Restaurants/barsView";
import descriptionView from "./views/Restaurants/descriptionView";
//index view
import navView from "./views/navView";
import hotSpotsView from "./views/hotSpotsView";
//shopping  view
import shoppingView from "./views/shoppingView";
//parks view
import parksView from "./views/Parks/parksView";
import parkOptionsView from "./views/Parks/parkOptionsView";
//cities view
import citiesView from "./views/Cities/citiesView";
import citiesOptionsView from "./views/Cities/citiesOptionsView";

// Show specific restaurant or bar, description, marker
const controlMapMarker = function (id, type) {
  if (type === "bar") {
    const bar = bars.filter((el) => el.id === id);
    barsView.removeActiveClass("restaurant-active");
    barsView.addActiveClass(id, "restaurant-active");
    descriptionView.render(bar);
    mapView.moveMarker(bar[0]);
  } else {
    const restaurant = restaurants.filter((el) => el.id === id);
    restaurantView.removeActiveClass("restaurant-active");
    restaurantView.addActiveClass(id, "restaurant-active");
    descriptionView.render(restaurant);
    mapView.moveMarker(restaurant[0]);
  }
};

// List restaurants or bars; render Markers on Map
const controlList = function (type) {
  if (type === "bars") {
    barsView.render(bars);
    descriptionView.clearMarkUp();
    mapView.renderMarkers(bars);
  } else {
    restaurantView.render(restaurants);
    descriptionView.clearMarkUp();
    mapView.renderMarkers(restaurants);
  }
};

// Render parks
// if model is really needed then create one.
const controlPark = function (parkId) {
  const [currentPark] = parks.filter((park) => park.id === parkId);
  parksView.render(currentPark);
  parksView._scrollToTitle();
};

// Render Cities
const controlCity = function (cityId) {
  const [currentCity] = cities.filter((city) => city.id === cityId);
  citiesOptionsView.removeActiveClass("city-active");
  citiesOptionsView.addActiveClass(cityId, "city-active");
  citiesView.render(currentCity);
  citiesView._scrollToTitle();
};

// Render  Markets


const init = function () {
  const path = window.location.pathname;
  if(path === "/" || path === "/index.html") new hotSpotsView();
  if (path === "/restaurants.html") {
    // initiate map with restaurants list
    mapView.renderMarkers(restaurants);
    restaurantView.render(restaurants);
    restaurantView.addHandlerRenderList(controlList);
    restaurantView.addHandlerToMap(controlMapMarker);
  } else if (path === "/parks.html") {
    // parks view
    parkOptionsView.render(parks);
    parkOptionsView.imageSlider(".selection__container", 2, 2);
    parksView.render(parks[0]);
    parkOptionsView.addHandlerRenderPark(controlPark);
  } else if (path === "/cities.html") {
    // cities view
    citiesOptionsView.render(cities);
    citiesOptionsView.addActiveClass(cities[0].id, "city-active");
    citiesView.render(cities[0]);
    citiesOptionsView.addHandlerRender(controlCity);
  }
};

init();
