import uniqid from "uniqid";
import mapSimien from "url:../../../img/map/parks/simien.png";
import mapBale from "url:../../../img/map/parks/bale.png";
import mapDanakil from "url:../../../img/map/parks/danakil.png";
import mapMenagesha from "url:../../../img/map/parks/menagesha.png";
import mapAwash from "url:../../../img/map/parks/awash.png";
import mapYangudi from "url:../../../img/map/parks/yangudi.png";
import mapNechisar from "url:../../../img/map/parks/nechisar.png";
import mapGambella from "url:../../../img/map/parks/gambella.png";
import mapOmo from "url:../../../img/map/parks/omo.png";
import mapMago from "url:../../../img/map/parks/mago.png";
import omo1 from "url:../../../img/park/omo/omo1.jpg";
import omo2 from "url:../../../img/park/omo/omo2.jpg";
import omo3 from "url:../../../img/park/omo/omo3.jpg";
import omo4 from "url:../../../img/park/omo/omo4.jpg";
import simien1 from "url:../../../img/park/simien/simien1.jpg";
import simien2 from "url:../../../img/park/simien/simien2.jpg";
import simien3 from "url:../../../img/park/simien/simien3.jpg";
import simien4 from "url:../../../img/park/simien/simien4.jpg";
import bale1 from "url:../../../img/park/bale/bale1.jpg";
import bale2 from "url:../../../img/park/bale/bale2.jpg";
import bale3 from "url:../../../img/park/bale/bale3.jpg";
import bale4 from "url:../../../img/park/bale/bale4.jpg";
import danakil1 from "url:../../../img/park/danakil/danakil1.jpg";
import danakil2 from "url:../../../img/park/danakil/danakil2.jpg";
import danakil3 from "url:../../../img/park/danakil/danakil3.jpg";
import danakil4 from "url:../../../img/park/danakil/danakil4.jpg";
import suba1 from "url:../../../img/park/suba/suba1.jpeg";
import awash1 from "url:../../../img/park/awash/awash1.jpg";
import awash2 from "url:../../../img/park/awash/awash2.jpg";
import awash3 from "url:../../../img/park/awash/awash3.jpg";
import awash4 from "url:../../../img/park/awash/awash4.jpg";
import nechisar1 from "url:../../../img/park/nechisar/nechisar1.jpg";
import nechisar2 from "url:../../../img/park/nechisar/nechisar2.jpg";
import nechisar3 from "url:../../../img/park/nechisar/nechisar3.jpg";
import nechisar4 from "url:../../../img/park/nechisar/nechisar4.jpg";
import mago1 from "url:../../../img/park/mago/mago1.jpg";
import mago2 from "url:../../../img/park/mago/mago2.jpg";
import mago3 from "url:../../../img/park/mago/mago3.jpg";

export default parks = [
  {
    id: uniqid(),
    name: "Simien Mountains National Park",
    description: `
    The Simien Mountains National Park is a picturesque landscape where massive erosion took place millions of years ago. This impact has since created jagged mountain peaks, deep valleys and is currently home to several globally threatened species, including the Gelada baboon, Ethiopian wolf, and the Walia ibex, a wild mountain goat found nowhere else in the world. This national park was established in 1969 and is the largest park in Ethiopia. It was also one of the first locations to be recognized by UNESCO as a World Heritage site. Therefore, it has required strict management and high levels of training and staff members to ensure the best condition of all its rare species and local communities at all times. One of the main attractions of the park is its biosphere. The cliffs are pretty steep, whereas the temperature is on the cooler side, making it suitable for the survival of all the hundreds of rare species located there.  </br>
          `,
    map: mapSimien,
    images: [simien1, simien2, simien3, simien4],
  },
  {
    id: uniqid(),
    name: "Bale Mountains National Park",
    description: `
    Bale Mountains National Park is a national park located in Addis Ababa, Ethiopia. This park is universally valuable for several reasons, including its breathtaking scenery, sweeping valleys, high mountains, and much more. It also has a vast expanse of forests, giving its visitors a diversity of unique views of the Ethiopian Highlands. The park also has a wide variety of wildlife, including some rare ones like the endangered Ethiopian wolves (Canis Sinensis) and a number of rare endemic amphibians. In addition to the endemic wildlife and beautiful scenery, the park also has impressive hiking routes where the visitors get to enjoy a little activity while observing the view of waterfalls and rushing streams. And for those wishing to take their time and look around longer, there are a couple of campsites around the park where most visitors tend to spend a couple of nights in.`,
    map: mapBale,
    images: [bale1, bale2, bale3, bale4],
  },
  {
    id: uniqid(),
    name: "Awash Danakil Depression",
    description: `
    Awash Danakil's depression is one of the lowest lands in the world, located on an island called Daset. It is also known to be one of the hottest and driest places. Lake Aftera supplies valuable salt on the shore to the traders in that region. Danakil depression contains hundreds of inactive volcanoes, so it is very common to see lava emerging from the cracked ground. The area is filled with "Natural Jacuzzis" thanks to the natural volcano springs during the dryer seasons, making it a huge tourist attraction.
    Tourists usually bathe in the hot spring water coming from underground. If you want a natural water spring and a break from cold and wind, this is the place to be. Make sure you pick the right season because the hot springs won't be available during the rainy seasons.    
    `,
    map: mapDanakil,
    images: [danakil1, danakil2, danakil3, danakil4],
  },
  {
    id: uniqid(),
    name: "Nechisar National Park ",
    description: `
    Nechisar National Park is a national park in Ethiopia and was established in 1974. The park's name translates to "white grass," which covers the park in the central plain areas. It's also one of the closest parks to the capital city, Addis Ababa, making it easily accessible to tourists and visitors. The park is very well known for its beautiful scenery, from the mountains and hills surrounding it to the large trees and extensive grasslands on the high plains. In addition, it's also known to have a variety of wildlife, including over 333 bird species, of which three are endemic. There are also plenty of mammals in the park, including the Burchell zebras, hippopotamus, lions, and leopards. The park is also famous for the hot springs located at the foot of Mt Tabala in the southeastern region.
    `,
    map: mapNechisar,
    images: [nechisar1, nechisar2, nechisar3, nechisar4],
  },
  {
    id: uniqid(),
    name: "Menagesha Suba Forest Park",
    description: `
    Menagesha Suba Forest Park is the oldest state park in Africa and is located in Ethiopia. There are plenty of activities that you can enjoy in this park. Firstly, it's pretty popular amongst hikers as you can enjoy a peaceful walk while appreciating the beautiful scenery and a wide range of wildlife. The park has identified over 186 bird species and 32 species of mammals over the past years. There are also over 167 tree species recorded which most of which are indigenous. This makes the park a perfect place to watch the nature and variety of wildlife. Many visitors also choose to spend a couple of nights as there are plenty of decent campsites around the park. It's also a popular spot to host several occasions such as weddings, birthdays, or other social events.    
    `,
    map: mapMenagesha,
    images: [
      suba1,
      "https://mediaim.expedia.com/localexpert/2546355/987a7313-dccc-4db5-9e2d-d872aabf1aad.jpg?impolicy=resizecrop&rw=1005&rh=565",
      "https://www.yaredtour.com/cache/image/16019005503coronamenag_1601900550__fi_960x960x1x1.jpg",
      "https://media.tacdn.com/media/attractions-splice-spp-720x480/07/87/83/7a.jpg",
    ],
  },
  {
    id: uniqid(),
    name: "Awash National Park",
    description: `
    Awash National Park is arguably one of the country's best wildlife reserves. It is home to a wide range of animals and covers an area of 756 square kilometers, mainly composed of grassland, acacia, and a range of volcanic landscapes. It was established in 1966, even though the act authorizing its existence wasn't ultimately passed for another three years. The park is transverse by several well-maintained tracks with the most spectacular scenery. While most of the land is covered by shrub savanna, the left shore of the Awash River is made up of a dense Gallery Forest. The park is also known to have a variety of wildlife, from over 46 major species of mammals to over 453 species of birds, among which which six are present only in Ethiopia. In addition to the wildlife, the place is also popular for the smoky waterfalls found at the end of the park.     
    `,
    map: mapAwash,
    images: [awash1, awash2, awash3, awash4],
  },
  {
    id: uniqid(),
    name: "Yangudi-Rassa National Park",
    description: `
    Yangudi-Rassa National Park is a national park located in the Afar region in Ethiopia. The park was essentially created to protect the African Wild Ass. A large portion of the park is filled with grasslands, rocky hills, and thickets. This park is also known for having a variety of wildlife. There have been over 200 birds recorded with about 23 Somali-Masai Biome species which are two globally threatened species. The area also has about 36 species of mammals recorded, such as the Bat-eared fox, Striped Hyena, and Aardwolf. The sandy semi-desert is also known to have some active volcanoes where you can witness a breathtaking sight of lava flowing from deep in the ground. It's one of the rare places in the world which features volcanic fountains, earthquakes, and salt hills.
    `,
    map: mapYangudi,
    images: [
      "https://www.hlimg.com/images/things2do/738X538/stanmeyer-camel-caravan1-990x660_1563438595-3397e.jpg",
      "https://tzmedia.b-cdn.net/media/images/et/place/max/bc9a9f5e06ebe4ef7c2dbe8615a4e8e9.jpg?1582270511",
      "https://www.worqambatour.com/img/slider_single_tour/Yangudi-national%20-park%20_6-min.jpg",
      "https://www.addisherald.com/wp-content/grand-media/image/Addis_herald_Yangudi_7.png",
    ],
  },
  {
    id: uniqid(),
    name: "Gambella National Park",
    description: `
    Gambella National Park is Ethiopia's largest national park and is located several kilometers away from the capital city, Addis Ababa. The park was initially focused on protecting three endangered species: the African elephant, Nile lechwe, and the shoebill stork. However, this park now has one of the greatest concentrations of wildlife in the whole country as there are over sixty-nine mammal species recorded there, including African Buffaloes, elephants, giraffes, leopards, cheetahs, lions, and monkeys. The park has also recorded over 327 bird species and seven species of reptiles. The landscape of the park is mostly situated in the low and flatlands of Ethiopia. Still, some higher areas are pretty rocky and have grounds with evanescent woodland and Savanna. In addition, this park has a few tented camps where most adventurous visitors tend to spend a couple of nights in.
    `,
    map: mapGambella,
    images: [
      "https://www.addisherald.com/wp-content/grand-media/image/g14.jpg",
      "https://www.addisherald.com/wp-content/grand-media/image/g12.jpg",
      "https://www.addisherald.com/wp-content/grand-media/image/african-buffalos-ethiopia.jpg",
      // "https://www.visit-ethiopia.org/indexJumbo.6b27b67a.jpeg",
    ],
  },
  {
    id: uniqid(),
    name: "Omo National Park ",
    description: `
    Omo National Park is a popular national park located in Ethiopia. This park is found in the Southern Nations and is one of the country's largest nature sanctuaries. It is located around the west bank of the Omo River. This spot is mainly gaining attraction from bird watchers as it has recorded over 312 different species within the park. The park also possesses a number of rivers and streams which drain to the Omo River, which was the place the park was named after. Furthermore, the park is also quite well known for its grasslands, hot springs, and riverine forests, which provide good wildlife habitat and consist of one of the largest diversities of mammalian species compared to other parks. There are also at least 13 species of fish in the various watercourses found around the park.
    `,
    map: mapOmo,
    images: [omo1, omo2, omo3, omo4],
  },
  {
    id: uniqid(),
    name: "Mago National Park",
    description: `
    Mago National Park is one of the national parks located in Southern Ethiopia. It was founded in 1979. This park is home to some of the most well-known safari animals. The vegetation there has more or less the same characteristics as the Savanna, with patchy grasslands, woodland, and river forests. There is also a wide variety of wildlife, including zebras, leopards, elephants, giraffes, and hyenas. In addition, the park is also known for its large Buffalo herds in the country, with past records showing that up to 2000 individual buffaloes were observed in this park. One of the other major attractions in the park is the hot springs which are populated with a rich diversity of ethnic groups coming from different regions of Ethiopia. 
    `,
    map: mapMago,
    images: [
      mago1,
      mago2,
      mago3,
      "https://www.addisherald.com/wp-content/grand-media/image/13.jpg",
    ],
  },
];
