import View from "../View";

class ParksView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".park");
  }

  _scrollToTitle() {
    this.element = document.querySelector(".park__title");
    this.element.scrollIntoView({ behavior: "smooth" });
  }

  createMarkUp() {
    return `
      <div class="park__info">
          <h1 class="park__title">
            ${this._data.name}
          </h1>
          <p class="park__desc">
            ${this._data.description}
          </p>
          <img
            class="park__map"
            src=${this._data.map}
            alt="National Bale Park Map"
          />
        </div>
        <div class="park__photos">
          ${this._data.images.map(this.addParkImages).join(" ")}
        </div>`;
  }

  addParkImages(parkImage, ind) {
    return `
      <img
        src=${parkImage}
        class="park__photos-${ind + 1}"
        alt="National Bale Park photo"
      />  
    `;
  }
}

export default new ParksView();
