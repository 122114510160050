import View from "../View";

class citiesOptionsView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".city__options");
  };

 

  addHandlerRender(handler) {
    document.addEventListener("click", function (e) {
      if (e.target.closest(".city__name")) {
        const cityId = e.target.closest(".city__name").id;
        handler(cityId);
      }
    });
  }

  createMarkUp() {
    return this._data
      .map((city) => {
        return `<p class="city__name" id=${city.id}>${city.name}</p>`;
      })
      .join(" ");
  }
}

export default new citiesOptionsView();

