class NavView {
  constructor() {
    this.openNav();
    this.closeNav();
  }

  openNav() {
    document.addEventListener("click", function (e) {
      if (e.target.closest(".nav-burger")) {
        document.querySelector(".nav").style.transform = "translateX(100vw)";
      }
    });
  }

  closeNav() {
    document.addEventListener("click", function (e) {
      if (e.target.closest(".nav-close")) {
        document.querySelector(".nav").style.transform = "translateX(-100vw)";
      }
    });
  }
}

export default new NavView();
