import View from "../View";

class RestaurantView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".restaurants__list");
  }

  //when a single restaurant get picked
  addHandlerToMap(handler) {
    document.addEventListener("click", function (e) {
      if (e.target.closest(".restaurant")) {
        let restaurant = e.target.closest(".restaurant");
        if (!restaurant) return;
        handler(restaurant.id, "restaurant");
      } else if (e.target.closest(".bar")) {
        let bar = e.target.closest(".bar");
        if (!bar) return;
        handler(bar.id, "bar");
      }
      return;
    });
  }

  //to render restaurants or bars list
  addHandlerRenderList(handler) {
    document.addEventListener("click", (e) => {
      if (e.target.closest(".bar-option")) {
        let bars = e.target.closest(".bar-option");
        this.removeActiveClass("option-active", bars.parentElement);
        this.addActiveClass(bars.id, "option-active", bars.parentElement);
        handler("bars");
      } else if (e.target.closest(".restaurant-option")) {
        let restaurants = e.target.closest(".restaurant-option");
        this.removeActiveClass("option-active", restaurants.parentElement);
        this.addActiveClass(
          restaurants.id,
          "option-active",
          restaurants.parentElement
        );
        handler("restaurants");
      } else {
        return;
      }
    });
  }

  // render list of restaurants
  createMarkUp() {
    return this._data.map((el) => {
      return `
        <li id=${el.id} class="restaurant">
        <img src=${el.img} alt="Restaurant Logo" />
        <div class="restaurant__info">
          <p class="restaurant__info-name">${el.name}</p>
          ${el.isVegan ? `<p><i class="fas fa-leaf"></i> Vegan</p>` : ""}
          <p>Hours:</p>
          <p>${el.hours}</p>
        </div>
      </li>
        `;
    });
  }
}

export default new RestaurantView();
