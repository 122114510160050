import View from "../View";

class citiesView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".city__info");
  }

  _scrollToTitle() {
    this.scrollToElement = document.querySelector(".city__desc");
    this.scrollToElement.scrollIntoView({ behavior: "smooth" });
  }

  createMarkUp() {
    return `
      <p class="city__desc">
        ${this._data.description}
      </p>
      <div class="city__map">
        <img src=${this._data.map} alt="${this._data.name} Map" />
      </div>
      <div class="city__images">
        ${this._data.images.map(this.cityImages).join(" ")}
      </div>
    `;
  }

  cityImages(cityImage) {
    return `
        <img
            src=${cityImage}
            alt="City Photo"
            class="city__img"
        />
    `;
  }
}

export default new citiesView();
