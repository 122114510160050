import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

export default class View {
  render(data) {
    this._data = data;

    this.clearMarkUp();

    const markup = this.createMarkUp(this._data);

    this.parentElement.insertAdjacentHTML("afterbegin", markup);
  }

  clearMarkUp() {
    this.parentElement.innerHTML = "";
  }

  removeActiveClass(className, parent = this.parentElement) {
    const elements = [...parent.children];
    elements.forEach((el) => el.classList.remove(className));
  }

  addActiveClass(id, className, parent = this.parentElement) {
    const list = [...parent.children];
    const element = list.filter((el) => el.id === id);
    element[0].classList.add(className);
  }

  imageSlider(className, slidesPerViewNum = 1, slidesPerGroupNum = 1, firstBreakpoint) {
    Swiper.use([Navigation]);
    return new Swiper(className, {
      speed: 500,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 30,
      loop: true,
      loopFillGroupWithBlank: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 499px
        500: {
            slidesPerView: firstBreakpoint ? firstBreakpoint : 2,
            slidesPerGroup: 1,
        },
        // when window width is >= 999px
        836: {
            slidesPerView: slidesPerViewNum,
            slidesPerGroup: slidesPerGroupNum,
        }
    }
    });
  }
}

// export default new View();
