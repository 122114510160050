import View from "../View";

class DescriptionView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".restaurant__desc");
  }
  createMarkUp() {
    return this._data.map((el) => {
      return `
              <p class="restaurant__desc-title">Description:</p>
              <p class="restaurant__desc-description">
                ${el.description}
              </p>
              <p class="restaurant__loc">Location: ${el.location}</p>
              ${
                el.isVegan
                  ? `<p class="restaurant__vegan><i class="fas fa-leaf"></i> Vegan</p>`
                  : ""
              }
            </div>
              `;
    });
  }
}

export default new DescriptionView();
