import uniqid from "uniqid";
import mapAddis from "url:../../../img/map/cities/addisababa.png";
import mapAwassa from "url:../../../img/map/cities/awassa.png";
import mapAxum from "url:../../../img/map/cities/axum.png";
import mapBahirdar from "url:../../../img/map/cities/BahirDar.png";
import mapDiredawa from "url:../../../img/map/cities/diredawa.png";
import mapGondar from "url:../../../img/map/cities/gondar.png";
import mapHarar from "url:../../../img/map/cities/harar.png";
import mapLalibela from "url:../../../img/map/cities/lalibela.png";
import axum1 from "url:../../../img/cities/axum/axum1.jpg";
import axum2 from "url:../../../img/cities/axum/axum2.jpg";
import axum3 from "url:../../../img/cities/axum/axum3.jpg";
import gondar1 from "url:../../../img/cities/gondar/gondar1.jpg";
import bahirdar1 from "url:../../../img/cities/bahirdar/bahirdar1.jpg";
import bahirdar2 from "url:../../../img/cities/bahirdar/bahirdar2.jpg";
import lalibela1 from "url:../../../img/cities/lalibela/lalibela1.jpg";
import lalibela2 from "url:../../../img/cities/lalibela/lalibela2.jpg";
import awassa1 from "url:../../../img/cities/awassa/awassa1.jpg";
import awassa2 from "url:../../../img/cities/awassa/awassa2.jpg";
import harar1 from "url:../../../img/cities/harar/harar1.jpg";
import harar2 from "url:../../../img/cities/harar/harar2.jpg";

export default cities = [
  {
    id: uniqid(),
    name: "Addis Ababa",
    description: `
    Addis Ababa is the capital city of Ethiopia. The name Addis Ababa means New Flower. It is one of the most populated cities in Africa. Addis Ababa houses people from all sides of Ethiopia. It is located in the Oromia region and is considered one of the fastest-growing cities globally. Addis Ababa has a lot of new recreation areas and tourist spots popping up all the time. There is a handful of museums and parks coming up for citizens and visitors to enjoy. One of the best places to go to if you want to learn about Ethiopian history is the Ethiopian National Museum located in Arat kilo and the red terror Meausem located in Meskel Square. It is also an essential hub for business and economic organizations in Africa. Did you know that the headquarters of the African Union is located in Addis Ababa? In addition, plenty of other international organizations have offices and embassies in Addis Ababa, making it Africa's political and economic capital. Addis Ababa is also home to the biggest airport in Ethiopia and one of the best-rated airports across Africa; Ethiopian Airlines. </br>
          `,
    map: mapAddis,
    images: [
      "https://media.tacdn.com/media/attractions-splice-spp-674x446/07/82/83/56.jpg",
      "https://www.blackpast.org/wp-content/uploads/Light_Rail_at_Lideta_station_Addis_Ababa_Ethiopia_2018.jpg",
      "https://answersafrica.com/wp-content/uploads/2015/01/1522690043-4073-Addis-Ababa-University3.jpg",
    ],
  },
  {
    id: uniqid(),
    name: "Axum",
    description: `
    Axum is one of the oldest cities in Ethiopia. It is located in the northern region of Tigray and has a population mainly consisting of Ethiopian Orthodox Christians.  It was known as the Axumite empire, which was a huge trading empire back in the 1700s. The Axumite Empire traded using their own coins and had their own language too. The Ethiopian Orthodox Christians believe that the Church of Our Lady Mary of Zion in Axum houses the original arch of the covenant, making Axum a major pilgrimage spot for Orthodox Christians. Christianity has major influences on Axum cultures because it was introduced to the region during its inception. Axum is known to be one of the first cities in Africa to accept Christianity. It is said that the  Emporer of the Axumite empire at that time too converted to Christianity. Its archaeological buildings also grab tourist's attention from all over the world. The city is full of churches and religious sites. Major sites to visit in Axum Include the Axum towers, located around different parts of the city. Unfortunately, some of the towers, also known as stelae, have been destroyed over time, but there are a few standing to this day. 
    </br>
          `,
    map: mapAxum,
    images: [axum1, axum2, axum3],
  },
  {
    id: uniqid(),
    name: "Gondar",
    description: `
    Gonder is one of the biggest cities in Ethiopia. It is located in the Northern Amhara region and used to be the Capital city of the Ethiopian empire. If you want a mix of cultural and religious heritage, then Gonder is the best city for that. Its people are very hospitable and patriotic. You can see the colors of the Ethiopian flag painted or hanged in most vehicles, buildings, and streets in every corner of the city. It is also a city known for its landscape, like the Simien mountains, with the largest park in Ethiopia. There are also churches located in the mountains. Tourists and Ethiopian Orthodox Christians come from across the country to see different museums and old churches in the hills for spiritual cleansing and pilgrimage. Gonder also has many historic sites like castles of different emperors that have ruled the Ethiopian empire since the 16th century. Gondar is also known to have the largest medical school with a well-equipped medical faculty. Even though Gonder's population is mostly Ethiopian Orthodox Christians, Gondar also is home to the largest Ethiopian Jew community that has remained in the country.
    </br>
          `,
    map: mapGondar,
    images: [
      gondar1,
      "https://leaveyourdailyhell.com/wp-content/uploads/2016/10/Gondar.jpg",
      "https://worldofwanderlust.com/wp-content/uploads/2019/11/Gondar-2-1024x683.jpg",
    ],
  },
  {
    id: uniqid(),
    name: "Bahir Dar",
    description: `
    Bahir Dar is the capital city of the Amhara region, one of the largest regions and second-largest ethnic group in Ethiopia. Bahirdar is a business attraction and a tourist destination at the same time. It is known for the beautiful sceneries of lake tana and the blue Nile river and is known to be one of the warmest cities in Ethiopia. It is a popular tourist attraction because of the comparatively well-developed infrastructure. Bahirdar has its own airport and is not very far from the capital city, making it a super convenient city for tourists. It is also known for having world-class hotels and resorts to give visitors a safe and comfortable stay.
    Bahir dar is surrounded by mountains and used to have gorgeous blue Nile waterfalls before the government built hydro plants around them to boost the electricity infrastructure in the country. Bahir dar is known for its lively nightlife and hospitable people too.
    Another thing Bahir dar is known for is the shopping scene. You can shop your heart out for authentic spices and fabrics, and carpets at a very reasonable price and excellent quality. 
          `,
    map: mapBahirdar,
    images: [
      bahirdar1,
      bahirdar2,
      "https://i.pinimg.com/originals/3d/bc/e5/3dbce5a5f04a07493d47402833d1d2f1.jpg",
    ],
  },
  {
    id: uniqid(),
    name: "Lalibela",
    description: `
    Lalibela is one of the oldest and holiest cities in Ethiopia. It is also one of the biggest tourist and pilgrimage attractions in Ethiopia. It is known for the historic buildings and churches that date back to the 7th century. Unesco has recognized the underground cross church as a world heritage site in 1978. Lalibela is located in the northern Amhara region, with the majority of its population being Christians. The people of Lalibela are highly religious and mostly consist of priests who live in churches and around them. It is also very common for women to wear the traditional headscarf around their heads, especially when they are around the churches.  Lalibela is also known to have the biggest Rock-cut Monolithic Churches. Historians claim that each church has been carved from a single rock, and many of the churches have tunnels connecting them together. Besides the churches, the city's architectural designs are unique and have been preserved for centuries. Lalibela has an airport making it a convenient destination to visit for tourists and visitors from other parts of the country. </br>
          `,
    map: mapLalibela,
    images: [
      lalibela1,
      lalibela2,
      "https://live.staticflickr.com/4911/45286807954_269b632d86_b.jpg",
    ],
  },
  {
    id: uniqid(),
    name: "Awassa",
    description: `
    Awassa is one of the most popular tourist attractions for local and international tourists. Awassa is the capital city of the Sidamo region, also known as the Southern Nations. It is located on the shore of the lake Awassa. Awassa is a city that boasts fantastic natural scenery and a city where you can have one of the best seafood in the country. Hawassa is a small city with most of its population belonging to the Oromia region. The people are hospitable, and the government has beautified the city to attract tourists. You can find classy hotels and resorts with world-class services all around the city. If you like to go to parks and enjoy mild tropical weather for most of the year, Awassa is a great city. Awassa also has one of the best nightlife in Ethiopia, with great availability of bars and clubs for a safe and fun experience for visitors. Transportation is not a problem in Hawassa thanks to the availability of an airport, so you don't have to worry about a long trip by car from Addis Ababa or a neighbouring large city.</br>
          `,
    map: mapAwassa,
    images: [
      awassa1,
      awassa2,
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Awassa%2C_2016.png",
    ],
  },
  {
    id: uniqid(),
    name: "Dire Dawa",
    description: `
    Diredawa is another province located in the eastern part of  Ethiopia. It is the 7th largest city in Ethiopia and the 4th most populous city. Dire Dawa is one of the most industrial cities in Ethiopia, therefore, attracting traders and business since the early 1900s. Dire Dawa is known for its hot weather and hospitable population. Until today, the city is still a major business attraction because it connects Ethiopia to the red-sea port through the Ethiopia Djibouti railway (Ethiopia is a land-locked country). Dire Dawa's people are mostly traders from the different parts of Ethiopia, and they also have traders that settled there from other countries from centuries ago. It is best to visit the city during its ceremony that happens twice a year in the region. That is when all the shops come together to gather up in a setting like a bazaar setting and sell clothes, spices, sweets, etc. The city is separated into two parts by a river, which also serves as a perfect spot to watch the sunset and sunrise for nature lovers.</br>
          `,
    map: mapDiredawa,
    images: [
      "https://borkena.com/wp-content/uploads/2018/08/Dire-Dawa.jpg",
      "https://www.diltour.com/wp-content/uploads/2018/07/dire-dawa-3-compressed.jpg",
      "https://i.pinimg.com/originals/58/32/dc/5832dce6365ea509548f3cf6aa1a5012.jpg",
    ],
  },
  {
    id: uniqid(),
    name: "Harar",
    description: `
    Harar was one of the top trading spots during the Ethiopian Empire and Arabian peninsula trade days. Harar is the capital city of the Harari region, located in Eastern Ethiopia. The city is known for its unique architecture and culture. Harar women have very distinct cultural clothes that consist of bright fabric and a lot of gold Jewellery put on the arms, head, and necks. Harar also has a very rich religious background for Muslims. Despite it being a relatively small city, it is one of the most religious Islamic destinations, with over 80 mosques built in there. Harar has been registered as a world site heritage by Unesco because of its breathtaking architecture and the history behind it. The city looks like a place straight from a history book thanks to the government law that banned demolishing any historic place in there. Harar is also known for its coffee. Harar coffee is the highest valued Ethiopian coffee in the export market. The coffee beans are known to be small and shiny when roasted, making them visually pleasing, and the aroma is breathtaking. Drinking Harar coffee is a must for any coffee lover visiting Ethiopia.</br>
          `,
    map: mapHarar,
    images: [
      harar1,
      harar2,
      "http://ai.stanford.edu/~latombe/mountain/photo/ethiopia-2012/harar/index_files/image003.jpg",
    ],
  },
];
