import View from "../View";

class BarView extends View {
  constructor() {
    super();
    this.parentElement = document.querySelector(".restaurants__list");
  }

  addHandlerToMap(handler) {
    document.addEventListener("click", function (e) {
      let bar = e.target.closest(".bar");
      if (!bar) return;
      handler(bar.id, "bar");
    });
  }

  createMarkUp() {
    return this._data.map((el) => {
      return `
          <li id=${el.id} class="bar">
          <img src=${el.img} alt="Restaurant Logo" />
          <div class="restaurant__info">
            <p class="restaurant__info-name">${el.name}</p>
            ${el.isVegan ? `<p><i class="fas fa-leaf"></i> Vegan</p>` : ""}
            <p>Hours:</p>
            <p>${el.hours}</p>
          </div>
        </li>
          `;
    });
  }

}

export default new BarView();
