import View from "./View";

class HotSpotsView extends View {
  constructor() {
    super();
    this.imageSlider(".selection__container", 4, 2);
  }
}

export default HotSpotsView;
