// import { L } from "leaflet";
class MapView {
  constructor() {
    this.parentElement = document.querySelector("#map");
    this.showMap();
  }

  moveMarker(restaurant) {
    this._map.panTo(restaurant.coordinates);
  }

  showMap() {
    if (window.location.pathname !== "/restaurants.html") return;
    this._map = L.map("map", {
      center: [9.005401, 38.763611],
      zoom: 14,
    });

    this._myToken =
      "pk.eyJ1IjoidmlzaXRldGhpb3BpYSIsImEiOiJja204OXRjcHowbXo3Mm5udTNlZnEyNWlsIn0.6TGhswXaSg9UC-C_jnCtYA";

    L.tileLayer(
      `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`,
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken: this._myToken,
      }
    ).addTo(this._map);
  }

  renderMarkers(data) {
    if (this._marker) this._marker.forEach((el) => this._map.removeLayer(el));
    this._marker = [];
    data.map((el) => {
      const marker = L.marker(el.coordinates).addTo(this._map);
      marker.bindPopup(`<h1 id=${el.id}>${el.name}<h1>`);
      this._marker.push(marker);
    });
  }
}

export default new MapView();
